<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <base-header
      :title="$t('app.divisas.name')"
      link="Dashboard"
    />
    <v-row justify="center">
      <v-col
        cols="12"
      >
        <base-material-card color="primary">
          <template v-slot:heading>
            <v-row>
              <v-col class="flex-grow-1 flex-grow-0">
                <div class="display-2 font-weight-light">
                  {{ $t('app.divisas.title') }}
                </div>

                <div class="subtitle-1 font-weight-light">
                  {{ $t('app.divisas.subtitle') }}
                </div>
              </v-col>
              <v-col class="d-flex align-center flex-grow-0 flex-shrink-0">
                <v-btn
                  dark
                  @click="openItemAdd"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-plus-circle
                  </v-icon>

                  {{ $t('app.buttons.add') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>

          <v-card-title>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="filters.selected.query"
                  :label="$t('app.buttons.search')"
                  rounded
                  outlined
                  dense
                  append-icon="mdi-magnify"
                  clearable
                />
              </v-col>
            </v-row>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="10"
            :search="queryClean"
            :custom-filter="customFilter"
          >
            <template v-slot:item.actions="{ item }">
              <v-col class="d-flex justify-end">
                <v-btn
                  icon
                  @click="openItemDetail(item)"
                >
                  <v-icon>
                    mdi-eye
                  </v-icon>
                </v-btn>

                <v-btn
                  icon
                  @click="openItemEdit(item)"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>

                <v-btn
                  icon
                  @click="openItemDelete(item)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-col>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>

    <divisas-add />
    <divisas-edit />
    <divisas-detail />
    <divisas-delete />
  </v-container>
</template>

<script>
import latinize from 'latinize'
import customFilter from "../../../../plugins/customFilter";

  export default {
    name: 'Divisas',
    mixins: [customFilter],
    components: {
      DivisasAdd: () => import('./DivisasAdd'),
      DivisasEdit: () => import('./DivisasEdit'),
      DivisasDetail: () => import('./DivisasDetail'),
      DivisasDelete: () => import('./DivisasDelete'),
    },
    data () {
      return {
        headers: [
          {
            text: this.$t('app.headers.id'),
            value: 'id',
          },
          {
            text: this.$t('app.headers.name'),
            value: 'name',
          },
          {
            text: this.$t('app.headers.shortname'),
            value: 'shortname',
          },
          {
            text: this.$t('app.headers.symbol'),
            value: 'symbol',
          },
          {
            text: this.$t('app.headers.code'),
            value: 'code',
          },
          {
            text: '',
            value: 'actions',
            sortable: false,
          },
        ],
        items: [],
        filters: {
          data: {
            items: [],
          },
          selected: {
            items: [],
            query: '',
          },
        },
      }
    },
    computed: {
      queryClean () {
        return latinize(this.filters.selected.query)
      }
    },
    mounted () {
      this.init()

      EventBus.$on('reload-items', () => {
        this.init()
      })
    },
    methods: {
      async init () {
        this.toggleLoader()

        this.items = await this.getDivisas()

        this.toggleLoader()
      },
      openItemAdd () {
        EventBus.$emit('divisas-add')
      },
      openItemEdit (item) {
        EventBus.$emit('divisas-edit', item)
      },
      openItemDetail (item) {
        EventBus.$emit('divisas-detail', item)
      },
      openItemDelete (item) {
        EventBus.$emit('divisas-delete', item)
      },
    },
  }
</script>

<style scoped>

</style>
